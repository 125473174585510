<!--
  ~ /*
  ~  * Copyright 2016-2017 the original author or authors.
  ~  *
  ~  * Licensed under the Apache License, Version 2.0 (the "License");
  ~  * you may not use this file except in compliance with the License.
  ~  * You may obtain a copy of the License at
  ~  *
  ~  *      http://www.apache.org/licenses/LICENSE-2.0
  ~  *
  ~  * Unless required by applicable law or agreed to in writing, software
  ~  * distributed under the License is distributed on an "AS IS" BASIS,
  ~  * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  ~  * See the License for the specific language governing permissions and
  ~  * limitations under the License.
  ~  */
  -->

<div class="container-fluid">
  <div class="container xd-container">

    <h2>
      Pet
    </h2>
    <form class="form-horizontal" (ngSubmit)="onSubmit(petForm.value)" #petForm="ngForm">
      <div class="form-group" hidden="true">
        <input type="text" hidden="true" class="form-control" id="id" [(ngModel)]="pet.id" name="id"/>
        <input type="text" hidden="true" class="form-control" id="owner" [(ngModel)]="pet.owner" name="owner"/>
      </div>
      <div class="form-group">
        <label for="owner" class="col-sm-2 control-label">Owner</label>
        <div class="col-sm-10">
          <input id="owner_name" name="owner_name" class="form-control" type="text"
                 value="{{ currentOwner.firstName }} {{ currentOwner.lastName }}" readonly/>
        </div>
      </div>
      <br/>
      <div class="form-group has-feedback" [class.has-success]="name.dirty && name.valid" [class.has-error]="name.dirty &&  !name.valid">
        <label for="name" class="col-sm-2 control-label">Name</label>
        <div class="col-sm-10">
          <input id="name" name="name" class="form-control" required type="text" [(ngModel)]="pet.name" #name="ngModel"/>
          <span class="glyphicon form-control-feedback" [class.glyphicon-ok]="name.valid" [class.glyphicon-remove]="!name.valid" aria-hidden="true"></span>
          <span class="help-block" *ngIf="name.dirty && name.hasError('required')">Name is required</span>
          <span class="help-block" *ngIf="name.dirty && name.hasError('minlength')">Name must be at least 2 characters long</span>

        </div>
      </div>
      <div class="form-group has-feedback" [class.has-success]="birthDate.dirty && birthDate.valid" [class.has-error]="birthDate.dirty && !birthDate.valid">
        <label class="col-sm-2 control-label">Birth Date</label>
        <div class="col-sm-10">
        <input matInput [matDatepicker]="birthDateDatepicker"  required [ngModel]="pet.birthDate | date:'yyyy-MM-dd'" name="birthDate" #birthDate="ngModel">
        <mat-datepicker-toggle matSuffix [for]="birthDateDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #birthDateDatepicker></mat-datepicker>
          <span class="glyphicon form-control-feedback" [class.glyphicon-ok]="birthDate.valid" [class.glyphicon-remove]="!birthDate.valid" aria-hidden="true"></span>
          <span class="glyphicon form-control-feedback" [class.glyphicon-ok]="birthDate.valid" [class.glyphicon-remove]="!birthDate.valid" aria-hidden="true"></span>
          <span class="help-block" *ngIf="birthDate.dirty && birthDate.hasError('required')">BirthDate is required</span>
        </div>
      </div>
      <div class="control-group">
        <div class="form-group has-feedback" [class.has-success]="pettype.dirty && pettype.valid" [class.has-error]="pettype.dirty && !pettype.valid">
          <label for="type" class="col-sm-2 control-label">Type </label>
          <div class="col-sm-10">
            <div class="col-sm-2">
              <input id="type1" name="type1" class="form-control" type="text" value="{{ currentType.name }}" readonly/>
            </div>
            <div class="col-sm-8">
              <select id="type" name="pettype" class="form-control" required [(ngModel)]="currentType"  #pettype="ngModel">
                <option *ngFor="let type of petTypes"
                        [selected]="type.id == currentType.id ? true : null"
                        [ngValue]="type">{{ type.name }}
                </option>
              </select>
              <span class="glyphicon form-control-feedback" [class.glyphicon-ok]="pettype.valid" [class.glyphicon-remove]="!pettype.valid" aria-hidden="true"></span>
              <span class="help-block" *ngIf="pettype.dirty && pettype.hasError('required')">First name is required</span>
              <span class="help-block" *ngIf="pettype.dirty && pettype.hasError('minlength')">First name must be at least 2 characters long</span>

            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-offset-2 col-sm-10">
          <br/>
          <button class="btn btn-default" type="button" (click)="gotoOwnerDetail(pet.owner)">< Back</button>
          <button class="btn btn-default" type="submit">Update Pet</button>
        </div>
      </div>
    </form>
  </div>
</div>
