<div class="container-fluid">
  <div class="container xd-container">
    <h2>New Specialty</h2>
    <form class="form-horizontal" (ngSubmit)="onSubmit(specialityForm.value)" #specialtyForm="ngForm">
      <div class="form-group" hidden="true">
        <input type="text" hidden="true" class="form-control" id="id" [(ngModel)]="speciality.id" name="id" />
      </div>
      <div *ngIf="
          specialityName.invalid &&
          (specialityName.dirty || specialityName.touched)
        " class="alert alert-danger">
        Loading...
        <div *ngIf="specialityName.errors?.required">Name is required.</div>
      </div>

      <div class="form-group has-feedback">
        <div class="form-group">
          <label class="col-sm-1 control-label">Name</label>
          <div class="col-sm-6">
            <input id="name" name="name" class="form-control" type="text" [(ngModel)]="speciality.name" name="specialityName" #specialityName="ngModel" />
          </div>
          <button class="btn btn-default" type="submit">Save</button>
        </div>
      </div>
    </form>
  </div>
</div>