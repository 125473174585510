<!--
  ~ /*
  ~  * Copyright 2016-2017 the original author or authors.
  ~  *
  ~  * Licensed under the Apache License, Version 2.0 (the "License");
  ~  * you may not use this file except in compliance with the License.
  ~  * You may obtain a copy of the License at
  ~  *
  ~  *      http://www.apache.org/licenses/LICENSE-2.0
  ~  *
  ~  * Unless required by applicable law or agreed to in writing, software
  ~  * distributed under the License is distributed on an "AS IS" BASIS,
  ~  * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  ~  * See the License for the specific language governing permissions and
  ~  * limitations under the License.
  ~  */
  -->

<div class="container-fluid">
  <div class="container xd-container">

    <h2>Veterinarians</h2>

    <table id="vets" class="table table-striped">
      <thead>
      <tr>
        <th>Name</th>
        <th>Specialties</th>
        <th></th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let vet of vets">
        <td>
          {{ vet.firstName }} {{ vet.lastName }}
        </td>
        <td>
          <div *ngFor="let spec of vet.specialties">
            {{ spec.name }}
          </div>
        </td>
        <td>
          <button class="btn btn-default" (click)="editVet(vet)">Edit Vet</button>
          <button class="btn btn-default" (click)="deleteVet(vet)">Delete Vet</button>
        </td>
      </tr>
      </tbody>
    </table>
    <div>
      <button class="btn btn-default" (click)="gotoHome()">Home</button>
      <button class="btn btn-default" (click)="addVet()">Add Vet</button>
    </div>
  </div>
