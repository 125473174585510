<!--
  ~ /*
  ~  * Copyright 2016-2017 the original author or authors.
  ~  *
  ~  * Licensed under the Apache License, Version 2.0 (the "License");
  ~  * you may not use this file except in compliance with the License.
  ~  * You may obtain a copy of the License at
  ~  *
  ~  *      http://www.apache.org/licenses/LICENSE-2.0
  ~  *
  ~  * Unless required by applicable law or agreed to in writing, software
  ~  * distributed under the License is distributed on an "AS IS" BASIS,
  ~  * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  ~  * See the License for the specific language governing permissions and
  ~  * limitations under the License.
  ~  */
  -->

<div class="container-fluid">
  <div class="container xd-container">

    <h2>Owner Information</h2>


    <table class="table table-striped">
      <tr>
        <th>Name</th>
        <td><b class="ownerFullName">{{ owner.firstName }} {{ owner.lastName }}</b></td>
      </tr>
      <tr>
        <th>Address</th>
        <td>{{ owner.address }}</td>
      </tr>
      <tr>
        <th>City</th>
        <td>{{ owner.city }}</td>
      </tr>
      <tr>
        <th>Telephone</th>
        <td>{{ owner.telephone }}</td>
      </tr>
    </table>

    <button class="btn btn-default" (click)="gotoOwnersList()">Back</button>
    <button class="btn btn-default" (click)="editOwner()">Edit Owner</button>
    <button class="btn btn-default" (click)="addPet(owner)">Add New Pet</button>

    <br />
    <br />
    <br />
    <h2>Pets and Visits</h2>

    <table class="table table-striped">
      <tr>
        <app-pet-list *ngFor="let pet of owner.pets" [pet]="pet"></app-pet-list>
      </tr>
    </table>
  </div>
</div>