<!--
  ~ /*
  ~  * Copyright 2016-2017 the original author or authors.
  ~  *
  ~  * Licensed under the Apache License, Version 2.0 (the "License");
  ~  * you may not use this file except in compliance with the License.
  ~  * You may obtain a copy of the License at
  ~  *
  ~  *      http://www.apache.org/licenses/LICENSE-2.0
  ~  *
  ~  * Unless required by applicable law or agreed to in writing, software
  ~  * distributed under the License is distributed on an "AS IS" BASIS,
  ~  * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  ~  * See the License for the specific language governing permissions and
  ~  * limitations under the License.
  ~  */
  -->

<table [hidden]="noVisits" class=" table table-condensed">
  <thead>
  <tr>
    <th>Visit Date</th>
    <th>Description</th>
    <th>Actions</th>
  </tr>
  </thead>
  <tr *ngFor="let visit of visits">
    <td>{{ visit.date }}</td>
    <td>{{ visit.description }}</td>
    <td>
      <button class="btn btn-default" (click)="editVisit(visit)">Edit Visit</button>
      <button class="btn btn-default" (click)="deleteVisit(visit)">Delete Visit</button>
    </td>
  </tr>
</table>





